import "./src/styles/globals.scss";
import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
export const wrapRootElement = ({ element }) => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RE_CAPTCHA_KEY}>
            {element}
        </GoogleReCaptchaProvider>
    )
}
